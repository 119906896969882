// import { BrowserRouter } from "react-router-dom";

import "./App.css";

import Navbar from "./components/Navbar";
import Carousel from "./components/Carousel";
import About from "./components/About";
import Services from "./components/Services";
import Team from "./components/Team";
import Footer from "./components/Footer";
import Contact from "./components/Contact";

const App = () => {
  return (
    // <BrowserRouter>
    <>
      <Navbar />
      <Carousel />
      <About />
      <Services />
      <Team />
      <Contact />
      <Footer />
    </>

    // </BrowserRouter>
  );
};

export default App;