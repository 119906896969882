import construction1 from "../img/projects/construction-1.jpg"
import construction2 from "../img/projects/construction-2.jpg"

const About = () => {
  return (
    <>
      {/* Light BG Section  */}
      <section id="about" className="p-md-5 py-4" style={{background: '#FFD000', color: '#262626'}}>
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md">
              <img
                src={construction1}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-md p-md-5 py-4">
              <h2>Swastik Nepal Construction</h2>
              <p className="lead fst-italic">
                Quality is not only our standard. It's also an attitude
                instilled in our company.
              </p>
              <p>
                When it comes to the provision of experienced, reliable and
                professional solutions across a broad range of services
                including construction, design and many more Civil Engineering
                contracting services, Swastik Nepal Construction is the best
                choice. We pride ourselves on providing a level of quality
                services. We operate on the simple philosophy of delivering
                outstanding customer service. Committing to quality, safety and
                integrity.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Light BG Section Ends  */}

      {/* Dark Bg Section  */}
      <section className="p-md-5 py-4 bg-dark text-light">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md p-md-5 py-4">
              <h2>The way we do business</h2>
              <p className="lead">Our Approach</p>
              <p>
                It has been our mission to make custom home design and
                construction, an experience that our clients enjoy. With a
                genuine enthusiasm for what do we apply in depth building and
                design experience to ensure you a rewarding and personalized
                process from start to finish. This is what sets us apart shapes
                our values, and the way we do business.
              </p>
            </div>
            <div className="col-md">
              <img
                src={construction2}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      {/* Dark Bg Section Ends  */}
    </>
  );
};

export default About;
