import logo from "../img/logo-1.jpg";

const Navbar = () => {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark m-0 bg-dark fixed-top"
    >
      <div className="container">
        <a className="navbar-brand" href="/">
          <div className="d-flex flex-row">
            <div>
              <img src={logo} alt="logo" height={50} />
            </div>
            <div className="d-flex flex-column">
              <div>
                <span className="text-warning">SWASTIK</span> NEPAL
              </div>
              <div className="very-small">CONSTRUCTION PVT. LTD.</div>
            </div>
          </div>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link" href="#about">
                About
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#services">
                Services
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#team">
                Team
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact">
                ContactUs
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
