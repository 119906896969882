import React from "react";

const ServiceCard = ({ bgColor, icon, title }) => (
  <div className="col-md">
    <div className={`card ${bgColor} text-light`}>
      <div className="card-body">
          <i className={`h1 bi ${icon}`}></i>
        <h4 className="card-title mt-3">{title}</h4>
      </div>
    </div>
  </div>
);

const Services = () => {
  const services = [
    {
      bgColor: "bg-dark",
      icon: "bi-building",
      title: "Architectural and Engineering",
    },
    {
      bgColor: "bg-secondary",
      icon: "bi-cone-striped",
      title: "Design Consultation and Construction",
    },
    {
      bgColor: "bg-dark",
      icon: "bi-building",
      title: "Roads, Buildings or Bridge Construction",
    },
    { bgColor: "bg-dark", icon: "bi-building", title: "Building Repair" },
    { bgColor: "bg-secondary", icon: "bi-person-square", title: "Foundation" },
    { bgColor: "bg-dark", icon: "bi-hammer", title: "Demolition" },
  ];

  return (
    <section className="p-md-5 py-4 service-gradient text-center" id="services">
      <div className="container">
        <div className="text-light">
          <h2>Our Services</h2>
          <p className="lead">Let's Build Something together</p>
        </div>
        <div className="row g-4 text-center text-light align-items-center mt-3">
          {services.slice(0, 3).map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
        <div className="row g-4 text-center text-light align-items-center mt-3">
          {services.slice(3, 6).map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
