const Footer = () => {
    return ( 
        <footer className="p-1 bg-dark text-white text-center position-relative">
        <div className="container">
          <p className="text-secondary small">
            Copyright &copy; Swastik Nepal Construction | Developed By: Suraj Pathak
          </p>
          <a href="/" className="position-absolute bottom-0 end-0 p-md-5 py-2">
            <i className="bi bi-arrow-up-circle h1"></i>
          </a>
        </div>
      </footer>
     );
}
 
export default Footer;