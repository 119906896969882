import carousel1 from "./carousel-1.jpg";
import carousel2 from "./carousel-2.jpg";
import carousel3 from "./carousel-3.jpg";
import carousel4 from "./carousel-4.jpg";
import house0 from "./house0.jpg";
import house1 from "./house1.jpg";
import house2 from "./house2.jpg";

export const heroImages = {
  house0,
  house1,
  house2,
  carousel1,
  carousel2,
  carousel3,
  carousel4,
};
